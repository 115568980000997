// TODO: PROTOSTAGE: Remove entire file, instead use mpx-sdk if it's used anywhere
class API {
	apiBaseUrl: string;

	constructor() {
		this.apiBaseUrl = process.env.NEXT_PUBLIC_MPS_API_HOST || '';
	}

	request = async (accessToken: string, method: 'GET' | 'POST' | 'PUT', path: string, body?: any) => {
		try {
			let url = `${this.apiBaseUrl}${path}`;
			if (path === '/firebase/auth') {
				url = `${process.env.NEXT_PUBLIC_TOKEN_API_URL}${path}`;
			}
			const response = await fetch(url, {
				method,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': path !== '/firebase/auth' ? 'application/json' : 'text/plain',
				},
				body: JSON.stringify(body),
			});

			const data = await response.json();

			return response.ok ? data : await Promise.reject(data);
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	getFirebaseToken = async (accessToken: string) => {
		const data = await this.request(accessToken, 'GET', '/firebase/auth');

		const customToken = data?.token || data?.customToken;

		return customToken;
	};
}

const api = new API();
export default api;
