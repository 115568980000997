import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CreditsIconUnstyledIcon, HistoryIcon, LightbulbIcon, RemixAIIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { useDialogService } from '@mpx-sdk/ui/components';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { OnboardingDialog } from '@mpx/components/generate/onboarding';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { type ReactElement, useMemo } from 'react';

export default function GenerateNavbar(): ReactElement | null {
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const pathname = usePathname();
	const responsiveView = UIHelper.isResponsiveView();
	const router = useRouter();
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const iconStyling = {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		margin: '1%',
	};

	const navOptions = [
		{
			title: 'Text-to-3D',
			icon: <RemixAIIcon style={iconStyling} />,
			id: 'create-display',
			url: '/generate',
		},
		{
			title: 'Inspiration',
			icon: <LightbulbIcon style={iconStyling} />,
			id: 'gallery-display',
			url: '/generate/gallery',
		},
		{
			title: 'History',
			icon: <HistoryIcon style={iconStyling} />,
			id: 'history-display',
			url: inApp ? '/storage?tab=my-generations' : '/generate/history',
		},
		{
			title: 'Get More Credits',
			icon: <CreditsIconUnstyledIcon style={iconStyling} />,
			id: 'credits-display',
			hide: !responsiveView,
			onClick: () => {
				showDialog(BuyMoreCredit.getAsDialog());

				DataLayer.triggerMeasurementEvent('buyEvent', {
					event_name: 'click_get_more_credits',
					user_id: currentUser?.id,
					user_number_of_created_models: userHistory?.length ?? 0,
					location: 'panel-sidebar',
				});
			},
		},
	];

	const navButtons = useMemo(
		() => (
			<>
				{/* Icon Buttons for nav options with icon above, text under icon */}
				{navOptions.map(({ title, icon, id, hide, onClick, url }) => {
					if (hide) {
						return null;
					}

					const iconButton = (
						<IconButton
							key={`sub-navbar-${title}-${id}`}
							aria-label={`Navigate to ${title} page`}
							data-testid={`${id}`}
							onClick={() => {
								onClick?.();

								if (id === 'history-display' && inApp) {
									router.push('/storage?tab=my-generations', {
										shallow: true,
									});
								}
							}}
							sx={{
								borderRadius: responsiveView ? '0px' : '15%',
								color: pathname === url ? 'primary.main' : 'text.primary',
								fontSize: '1.143rem',
								fontStyle: 'normal',
								fontWeight: 600,
								justifyContent: responsiveView ? 'flex-start' : 'center',
								leadingTrim: 'both',
								lineHeight: '43.442px',
								margin: '1%',
								paddingLeft: responsiveView ? '1.4rem' : 'inherit',
								textEdge: 'cap',
								width: '100%',
								svg: {
									fill: 'text.primary',
								},
							}}
						>
							<Stack
								alignItems='center'
								direction={responsiveView ? 'row' : 'column'}
								justifyContent={responsiveView ? 'flex-start' : 'center'}
								spacing={responsiveView ? 2 : 0}
							>
								<Box>{icon}</Box>

								<Box>{title}</Box>
							</Stack>
						</IconButton>
					);

					return url ? (
						<Link
							key={`sub-navbar-${title}-${id}`}
							href={url}
							shallow
							style={{
								width: '100%',
								backgroundColor:
									responsiveView && pathname === url ? 'rgba(0, 234, 183, 0.10)' : 'inherit',
								borderRight: responsiveView
									? `4px solid ${pathname === url ? '#00EAB7' : 'transparent'}`
									: 'inherit',
							}}
						>
							{iconButton}
						</Link>
					) : (
						iconButton
					);
				})}
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[inApp, pathname, responsiveView, router],
	);

	return (
		<AppBar
			className='navbar-appbar'
			data-testid='sub-navbar'
			position='static'
			sx={{
				backgroundColor: 'background.default',
				backgroundImage: 'none !important',
				borderRadius: '0px !important',
				borderRight: responsiveView ? (theme) => `1px solid ${theme.palette.borders.dark}` : 'none',
				borderTop: responsiveView ? 'none' : (theme) => `1px solid ${theme.palette.borders.dark}`,
				bottom: responsiveView ? 'inherit' : 0,
				boxShadow: 'none !important',
				flexDirection: responsiveView ? 'column' : 'inherit',
				justifyContent: 'space-between',
				maxWidth: responsiveView ? 'min(320px, 25%)' : '100vw',
				top: responsiveView ? 'inherit' : 'auto !important',
				width: '100%',
				zIndex: (theme) => (theme?.zIndex?.drawer || 10000) - 1,
			}}
		>
			<Toolbar
				disableGutters
				sx={{
					alignItems: responsiveView ? 'flex-start' : 'center',
					flexDirection: responsiveView ? 'column' : 'row',
					justifyContent: responsiveView ? 'flex-start' : 'space-around',
					padding: 0,
					paddingBottom: responsiveView ? 0 : '0.5%',
					paddingTop: responsiveView ? '1.5rem' : '0.5%',
					width: '100% !important',
					margin: '0 !important',
				}}
			>
				{navButtons}
			</Toolbar>

			{responsiveView && (
				<Box
					sx={{
						marginBottom: '5%',
					}}
				>
					<OnboardingDialog />
				</Box>
			)}
		</AppBar>
	);
}
