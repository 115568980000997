import { UIHelper } from '@mpx-sdk/helpers/ui';
import { MagicWandIcon, WarningIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import { GradientTypography } from '@mpx-sdk/ui/components';
import IconText from '@mpx-sdk/ui/components/core/IconText';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

function Slide2() {
	const inApp = useAtomValue(inAppBrowserAtom);
	const isMobile = UIHelper.isMobile();

	return (
		<Box>
			<Stack>
				<GradientTypography textAlign='left' variant={isMobile ? 'h6' : 'h4'}>
					Capabilities &amp; Limitations
				</GradientTypography>
			</Stack>
			<Stack
				spacing={isMobile ? 2 : 0}
				style={{
					display: 'flex',
					padding: isMobile ? '20px' : '40px',
					justifyContent: 'center',
					flexDirection: isMobile ? 'column' : 'row',
				}}
			>
				<Stack
					sx={{
						backgroundColor: isMobile ? '#303030' : '#444444',
						width: isMobile ? '100%' : '60%',
						height: 'auto',
						padding: '20px',
						borderRadius: '20px',
					}}
				>
					<IconText color='white' padding={isMobile ? '1px' : '5px'} variant='h6'>
						<i>
							<MagicWandIcon fontSize='small' />
							&nbsp; &nbsp;
						</i>
						Capabilities:
					</IconText>

					<Typography variant='body1'>
						<ul>
							<li>Create humans, animals, and object 3D models.</li>
							<li>Add animations to human models.</li>
							<li>
								Edit the generated models in VR
								{!inApp && (
									<>
										using the{' '}
										<Link
											href={urls.app.oculus}
											style={{
												color: '#0EEBBB',
												textDecoration: 'underline',
												fontWeight: 'bold',
											}}
										>
											Masterpiece X app on MetaQuest.
										</Link>
									</>
								)}
							</li>
							<li>Export to .GLB format</li>
						</ul>
					</Typography>
				</Stack>

				<Stack
					sx={{
						backgroundColor: isMobile ? '#303030' : '#444444',
						width: isMobile ? '100%' : '60%',
						height: 'auto',
						padding: '20px',
						borderRadius: '20px',

						mx: '5%',
					}}
				>
					<IconText color='white' padding={isMobile ? '1px' : '5px'} variant='h6'>
						<i>
							<WarningIcon fontSize='small' />
							&nbsp; &nbsp;
						</i>
						Limitations:
					</IconText>

					<Typography variant='body1'>
						<ul>
							<li>
								It&apos;s not for creating uncommon or obscure objects such as a “Gorilla without legs”.
							</li>
							<li>It may take multiple generations to get your desired result.</li>
						</ul>
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}

export default Slide2;
