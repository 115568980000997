import { DataLayer } from '@mpx-sdk/helpers/measurement';
import UIHelper from '@mpx-sdk/helpers/ui/UIHelper';
import { MagicWandIcon } from '@mpx-sdk/images';
import { userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import ButtonLink from '@mpx/app/generate/ButtonLink';
import Slide1 from '@mpx/components/generate/onboarding/Slide1';
import Slide2 from '@mpx/components/generate/onboarding/Slide2';
import Slide3 from '@mpx/components/generate/onboarding/Slide3';
import Slide4 from '@mpx/components/generate/onboarding/Slide4';
import { ArrowBackIos as ArrowBackIosIcon, Close as CloseIcon, HelpOutline } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { type ReactElement, useState } from 'react';

const slides = [
	{
		id: 'slide1',
		component: <Slide1 />,
		image: 'url("/1_Onboarding_Popup.jpg")',
		mobileImage: 'url("/1_Mobile_Onboarding.svg")',
	},
	{
		id: 'slide2',
		component: <Slide2 />,
		image: 'url("/2_Onboarding_Popup.jpg")',
		mobileImage: 'url("/2_Mobile_Onboarding.svg")',
	},
	{
		id: 'slide3',
		component: <Slide3 />,
		image: 'url("/3_Onboarding_Popup.jpg")',
		mobileImage: 'url("/3MobileOnboarding.jpg")',
	},
	{
		id: 'slide4',
		component: <Slide4 />,
		image: 'url("/4_Onboarding_Popup.jpg")',
		mobileImage: 'url("/4_Mobile_Onboarding.svg")',
	},
];

const slideLabels = [
	'Next: Capabilities & Limitations',
	'Next: The Steps',
	'Next: Final Thoughts',
	'Start Generating!',
];

interface OnboardingModalProps {
	/** Whether to display the text for the view tutorial button */
	displayButtonText?: boolean;
}

export default function OnboardingModal({ displayButtonText = true }: OnboardingModalProps): ReactElement {
	const [activeSlide, setActiveSlide] = useState(0);
	const [showDialog, setShowDialog] = useState(false);
	const currentUser = useAtomValue(userAtom);
	const isMobile = UIHelper.isMobile();
	const userHistory = useAtomValue(userHistoryAtom);

	const handleDialogOpen = () => {
		setShowDialog(true);

		// Reset the activeSlide to 0 when the dialog is opened
		setActiveSlide(0);

		DataLayer.triggerMeasurementEvent('onboarding', {
			event_name: 'tutorial_start',
			user_id: currentUser?.id,
			user_number_of_created_models: userHistory?.length ?? 0,
		});
	};

	const handleDialogClose = () => {
		setShowDialog(false);

		// Reset the activeSlide to 0 when the dialog is closed
		setActiveSlide(0);

		DataLayer.triggerMeasurementEvent('onboarding', {
			event_name: 'tutorial_close',
			slide_closed_on: activeSlide,
			total_slides: slides.length,
			user_id: currentUser?.id,
			user_number_of_created_models: userHistory?.length ?? 0,
		});
	};

	const handleSlideChange = (delta: number) => {
		const newIndex = activeSlide + delta;
		if (newIndex >= 0 && newIndex < slides.length) {
			setActiveSlide(newIndex);

			if (newIndex === slides.length - 1) {
				// Check if it's the last slide
				if (delta === 1) {
					// Check if the "Next" button was clicked
					DataLayer.triggerMeasurementEvent('onboarding', {
						event_name: 'tutorial_complete',
						user_id: currentUser?.id,
						user_number_of_created_models: userHistory?.length ?? 0,
						completed_onboarding: true,
					});
				}
			}
		}

		if (newIndex === slides.length) {
			// If the new index is beyond the last slide, close the dialog
			setShowDialog(false);
		}
	};

	let buttonWidth = 'auto';

	if (isMobile) {
		if (activeSlide === 0) {
			buttonWidth = '100%';
		} else {
			buttonWidth = '70%';
		}
	}

	return (
		<>
			<Box component='div' sx={{ pt: 1 }}>
				<Stack
					alignItems='center'
					direction='row'
					justifyContent='center'
					onClick={handleDialogOpen}
					spacing={1}
					sx={{
						cursor: 'pointer',
					}}
				>
					<Tooltip arrow describeChild title='Click to view tutorial'>
						<HelpOutline
							sx={{
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'center',
								margin: '1%',
							}}
						/>
					</Tooltip>

					{displayButtonText && (
						<Typography
							sx={{
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'center',
								margin: 'auto',
							}}
							textAlign='center'
						>
							View Tutorial <span style={{ marginRight: '1px' }} />
							<ButtonLink
								key='tutorial-button'
								color='#0EEBBB'
								data-cypress='TutorialButton'
								fontWeight='bold'
								id='genai-onboarding-button'
								sx={{
									marginLeft: '0.5rem',
								}}
							>
								HERE
							</ButtonLink>
						</Typography>
					)}
				</Stack>
			</Box>

			<Dialog
				maxWidth='md'
				onClose={handleDialogClose}
				open={showDialog}
				PaperProps={{
					sx: {
						mx: {
							xs: 2,
							md: 0,
						},
						background: isMobile ? slides[activeSlide].mobileImage : slides[activeSlide].image,
						backgroundPosition: 'top left',
						backgroundSize: 'cover',
						height: '100%',
						maxHeight: { sm: '35rem' },
						p: 0,
						width: '100%',
					},
				}}
				sx={{ overflow: 'auto', height: '100%' }}
			>
				<Stack sx={{ position: 'relative', height: '100%', px: { xs: 2, sm: 6 }, py: 4 }}>
					{/* Counter */}
					<Typography fontWeight='bold' mb={1} variant={isMobile ? 'body1' : 'h5'}>
						{activeSlide + 1}/{slides.length}
					</Typography>
					{/* Content */}
					{slides[activeSlide].component}

					{/* Buttons */}
					<Stack
						padding={1}
						sx={{
							bottom: '3%',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: activeSlide > 0 ? 'space-between' : 'flex-end',
							left: '3%',
							mt: 'auto',
						}}
						zIndex={1}
					>
						{activeSlide > 0 && ( // Only render the "Back" button when not on the first slide
							<Button
								aria-label='back'
								color='primary'
								data-cypress='TutorialSlideBackBtn'
								onClick={() => handleSlideChange(-1)}
								sx={{
									borderColor: 'white',
									color: 'white',
									fontSize: isMobile ? '1.2rem' : '0.9rem',
									mt: isMobile ? 2 : 0,
									textDecoration: isMobile ? 'underline' : 'none',
								}}
								variant={isMobile ? undefined : 'outlined'}
							>
								{isMobile ? (
									<>
										<ArrowBackIosIcon sx={{ fontSize: '1.2rem', marginRight: '4px' }} />
										Back
									</>
								) : (
									'Back'
								)}
							</Button>
						)}

						<Button
							color='primary'
							data-cypress='TutorialSlideNextBtn'
							onClick={() => (slides?.[activeSlide + 1] ? handleSlideChange(1) : handleSlideChange(1))}
							sx={{
								background: (theme) => theme.palette.gradient.main,
								//	backgroundColor: 'original.main',
								fontWeight: '700',
								mb: 1,
								mt: isMobile ? 2 : 0,
								p: isMobile ? 1.5 : 1,
								pl: isMobile ? 2 : 3,
								pr: isMobile ? 2 : 3,
								width: isMobile ? buttonWidth : 'auto',

								fontSize: isMobile ? '1.01rem' : '0.9rem',
								'&:hover': {
									background: (theme) =>
										isMobile ? theme.palette.gradient.main : theme.palette.gradient.hover,
								},
							}}
							variant='contained'
						>
							{(() => {
								if (isMobile) {
									return activeSlide === slides.length - 1 ? 'Start Generating' : 'Next';
								}
								if (activeSlide === slides.length - 1) {
									return (
										<>
											<MagicWandIcon
												sx={{
													height: '20px',
													width: '20px',
												}}
											/>
											{slideLabels[3]}
										</>
									);
								}
								return slideLabels[activeSlide];
							})()}
						</Button>
					</Stack>

					<IconButton
						aria-label='close'
						color='primary'
						data-cypress='TutorialCloseButton'
						onClick={handleDialogClose}
						sx={{
							position: 'absolute',
							right: 35,
							top: 20,
							'&:hover': { backgroundColor: 'transparent' },
						}}
					>
						<CloseIcon fontSize={isMobile ? 'medium' : 'large'} style={{ color: '#EEEEEE' }} />
					</IconButton>
				</Stack>
			</Dialog>
		</>
	);
}
