'use client';

import { accessTokenProvider } from '@api-client/index';
import { genaiFirebase } from '@mpx-sdk/helpers/assets';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { userAtom } from '@mpx-sdk/shared/atoms';
import api from '@mpx/app/generate/api';
import GenerateNavbar from '@mpx/components/generate/sidebar/GenerateNavbar';
import { Box, Container } from '@mui/material';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useAtomValue } from 'jotai';
import { debounce } from 'lodash';
import { useEffect } from 'react';

interface GenerateLayoutProps {
	children: React.ReactNode;
}

export default function GenerateLayout({ children }: Readonly<GenerateLayoutProps>) {
	const currentUser = useAtomValue(userAtom);
	const responsiveView = UIHelper.isResponsiveView();

	const checkOnboarding = debounce(() => {
		const hasSeenWelcome = localStorage?.getItem('genAIOnboarding');

		if (!hasSeenWelcome && currentUser?.createdAt) {
			const today = new Date();
			const createdAtDate = new Date(currentUser.createdAt);

			const isToday =
				createdAtDate.getFullYear() === today.getFullYear() &&
				createdAtDate.getMonth() === today.getMonth() &&
				createdAtDate.getDate() === today.getDate();

			if (isToday && document?.getElementById('genai-onboarding-button')) {
				document.getElementById('genai-onboarding-button')?.click();

				if (localStorage) {
					localStorage?.setItem('genAIOnboarding', 'true');
				}
			}
		}
	}, 1000);

	/**
	 * Only happens on staging since there are two projects being used, 1. staging and 2. prototyping, where 3d services stuff is
	 */
	const firebaseLogin = async () => {
		const token = await accessTokenProvider.getAccessToken();
		if (process.env.NEXT_PUBLIC_TOKEN_API_URL && token) {
			api.getFirebaseToken(token ?? '').then((token) => {
				signInWithCustomToken(getAuth(genaiFirebase), token);
			});
		}
	};

	useEffect(() => {
		checkOnboarding();

		firebaseLogin();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: responsiveView ? 'row' : 'column-reverse',
			}}
		>
			<GenerateNavbar />

			<Box
				id='generate-layout'
				sx={{
					flex: 1,
					overflowY: 'auto',
				}}
			>
				{children}
			</Box>
		</Container>
	);
}
