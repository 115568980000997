import { UIHelper } from '@mpx-sdk/helpers/ui';
import { GradientTypography } from '@mpx-sdk/ui/components';
import { Box, Stack, Typography } from '@mui/material';

function Slide3() {
	const isMobile = UIHelper.isMobile();

	return (
		<Box>
			<Typography variant={isMobile ? 'h6' : 'h4'}>
				<Typography style={{ display: 'inline' }} variant='inherit'>
					The Steps :&nbsp;
				</Typography>
				<GradientTypography style={{ display: 'inline' }} variant='inherit'>
					Shape, Paint <br /> & Animate
				</GradientTypography>
			</Typography>
			<Stack
				component='ol'
				sx={{
					backgroundColor: isMobile ? '#303030' : '#444444',
					width: isMobile ? '100%' : '55%',
					borderRadius: '20px',
					mt: isMobile ? 15 : 4,
					py: 3,
					pr: 3,
				}}
				zIndex={1}
			>
				{[
					{
						title: 'Shape',
						description: "creates your model's mesh.",
					},
					{
						title: 'Paint',
						description:
							"creates your 3D model's texture. Compared to the Shape stage, the more descriptive you are, the better your results will be.",
					},
					{
						title: 'Animate',
						description: 'is for human models only. Using simple one word actions work best.',
					},
				].map((item) => (
					<Typography component='li' pb={2}>
						<Typography color='primary' component='span' fontWeight='bold'>
							{item.title}:&nbsp;
						</Typography>
						{item.description}
					</Typography>
				))}
			</Stack>
		</Box>
	);
}

export default Slide3;
